import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID;

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID || ''
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true
  }
};

import { FunctionComponent, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalInstance } from '@services';
import { InteractionType } from '@azure/msal-browser';
import { LoginError, LoginLoader } from '@components';
import { SEARCH_PARAMS_KEY, GRAPH_SCOPES } from '@constants';

export type AppMsalProviderProps = {
  children: ReactNode;
};

export const AppMsalProvider: FunctionComponent<AppMsalProviderProps> = ({
  children
}) => {
  const [searchParams] = useSearchParams();

  const loginHint = searchParams.get(SEARCH_PARAMS_KEY.CURRENT_USER_EMAIL);

  const authRequest = {
    scopes: GRAPH_SCOPES,
    loginHint: loginHint || ''
  };

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={LoginError}
        loadingComponent={LoginLoader}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

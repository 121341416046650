import { MSDriveList } from '@types';
import { getDriveList } from './get-drive-list';

export const getListGraphIdByName = async (
  siteId: string,
  listName: string
) => {
  const driveList: MSDriveList['value'] = await getDriveList(siteId);

  const drive = driveList.find((d) => d.name === listName);

  if (drive) return drive?.id;
};

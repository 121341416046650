import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import App from './app';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root')!);

console.log('v4-6-2023');

root.render(
  <BrowserRouter>
    <App />
    <Toaster />
  </BrowserRouter>
);

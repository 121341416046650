const isSupport = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch {
    console.error('localStorage support is not detected'); // eslint-disable-line no-console
    return false;
  }
};

export const getLocalStorageItem = (key: string) => {
  if (isSupport()) {
    const item = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
};

export const setLocalStorageItem = (key: string, value: unknown) => {
  if (isSupport()) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

import { useState } from 'react';
import {
  BasicDetailTab,
  AdminSettingsTab,
  UserSettingsTab,
  SideBar
} from '@components';
import { FiAlertCircle } from 'react-icons/fi';
import { BiUserPin } from 'react-icons/bi';
import { BsArrowRightCircle } from 'react-icons/bs';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { APP_ROUTE } from '@constants';

const navItems = [
  { name: 'Basic Detail', Icon: FiAlertCircle },
  { name: 'Admin Settings', Icon: BiUserPin },
  { name: 'User Settings', Icon: AiOutlineUserAdd }
];

export const Admin = () => {
  const [selectedNavName, setSelectedNavName] = useState(navItems[0].name);

  const renderPageTabContent = () => {
    switch (selectedNavName) {
      case 'Basic Detail':
        return <BasicDetailTab />;
      case 'Admin Settings':
        return <AdminSettingsTab />;
      case 'User Settings':
        return <UserSettingsTab />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex">
      <SideBar
        navItems={navItems}
        onClick={(name) => setSelectedNavName(name)}
      />
      <div className="w-full">
        <div className="flex h-64 w-full justify-between bg-gradient-to-tl from-red-100 to-red-500 p-12 text-white">
          <h1 className="uppercase">{selectedNavName}</h1>
          <Link
            to={APP_ROUTE.EDITOR}
            className="flex h-10 items-center gap-2 rounded-lg bg-white p-2 font-bold text-red-500 transition-colors hover:bg-gray-200"
          >
            <BsArrowRightCircle />
            Go Home
          </Link>
        </div>
        <div className="-mt-44 p-12 pb-0">{renderPageTabContent()}</div>
      </div>
    </div>
  );
};

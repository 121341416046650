import { FunctionComponent, ReactNode } from 'react';

import './styles/screen-loading.scss';

export type ScreenLoadingProps = {
  children: ReactNode;
};

export const ScreenLoading: FunctionComponent<ScreenLoadingProps> = ({
  children
}) => {
  return (
    <h1 className="screen-loading flex h-screen items-center justify-center text-3xl">
      <div className="top-loading fixed top-0 w-screen h-1"></div>
      {children}
    </h1>
  );
};

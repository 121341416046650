import {
  APP_STATUS,
  HTTP_METHOD,
  HUBSPOT_BASE_URL,
  TOAST_TYPE
} from '@constants';
import { makeToast } from '@helpers';

export const hubspotExecutor = async (
  method: HTTP_METHOD,
  endpoint: string,
  payload?: unknown
) => {
  try {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const options = {
      method: method,
      headers: headers,
      body: JSON.stringify(payload)
    };

    const response = await fetch(`${HUBSPOT_BASE_URL}${endpoint}`, options);

    if (response.status === 204) return;
    return await response.json();
  } catch (error) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.HUBSPOT_SERVER_ERROR_TITLE,
      message: APP_STATUS.ERROR.HUBSPOT_SERVER_ERROR_BODY
    });
    throw error;
  }
};

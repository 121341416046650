import { TOAST_TYPE, APP_STATUS } from '@constants';
import { makeToast } from '@helpers';
import { Core } from '@pdftron/webviewer';
import {
  putCreateUploadSession,
  chunkingAndUploadFile,
  validateActions
} from '@services';
import { Subscription } from '@types';
import toast from 'react-hot-toast';

export const saveDocument = async (
  annotationManager: Core.AnnotationManager,
  document: Core.Document,
  subscription: Subscription,
  tenantId: string,
  tenantUserEmail: string,
  driveId: string,
  fileId: string
) => {
  const loadingToastId = makeToast({
    type: TOAST_TYPE.LOADING,
    message: APP_STATUS.INFO.SAVING_DOCUMENT,
    loading: false
  });
  
  const isActionsValid = await validateActions(
    subscription,
    tenantId,
    tenantUserEmail
  );
     
  //const isActionsValid = true;
  if (isActionsValid) {
    const [uploadSession, xfdfString] = await Promise.all([
      putCreateUploadSession(driveId, fileId),
      annotationManager.exportAnnotations()
    ]);

    const uploadUrl = uploadSession.uploadUrl as string;

    if (!uploadUrl) {
      toast.dismiss(loadingToastId);

      makeToast({
        message: APP_STATUS.ERROR.PDF_SAVED_MESSAGE,
        type: TOAST_TYPE.ERROR
      });
      return;
    }

    const fileData = await document.getFileData({ xfdfString });
     
    const fileArray = new Uint8Array(fileData);
    const blobFile = new Blob([fileArray], { type: 'application/pdf' });

    await chunkingAndUploadFile(blobFile, uploadUrl, loadingToastId);
  }
};

import { HTTP_METHOD } from '@constants';
import { Subscription } from '@types';
import { storageExecutor } from '@services';

export const deleteAccount = async (
  subscription: Subscription,
  tenantId: string,
  userEmail: string,
  accountType: string
): Promise<boolean> => {
  const url = `/delete/${subscription.subscriptionId};${tenantId}/${userEmail};${accountType}`;

  const headers: HeadersInit = {
    'If-Match': '*'
  };

  return storageExecutor(HTTP_METHOD.DELETE, url, null, headers);
};

import { USER_TYPE } from '@constants';
import { getAccount } from './get-account';

export const checkIsAdmin = async (
  subscriptionId: string,
  tenantId: string,
  emailAddress: string
): Promise<boolean> => {
  const adminTest = await getAccount(
    subscriptionId,
    tenantId,
    emailAddress,
    USER_TYPE.Admin
  );
  return adminTest?.value?.length >= 1;
};

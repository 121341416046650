import {
  getSubscriptionByRecurly,
  patchAddAccount,
  patchUpdateSubscriptionTenant
} from '@services';
import { makeToast } from '@helpers';
import { APP_STATUS, DEAL_STAGE, MAX_TENANTS_NUM, TOAST_TYPE, USER_TYPE } from '@constants';

export const registerTenant = async (
  subscriptionId: string,
  userEmail: string,
  tenantId: string,
  tenantEmail: string
) => {
  const subscription = await getSubscriptionByRecurly(
    subscriptionId,
    userEmail
  );

  if (!subscription || !subscription?.isValid) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.REGISTRATION_MISMATCH_TITLE,
      message: APP_STATUS.ERROR.REGISTRATION_MISMATCH_BODY
    });
    return false;
  }

  if (subscription.registeredTenantNum >= MAX_TENANTS_NUM) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.REGISTRATION_EXEEDED_NUMBER_OF_TENTANTS_TITLE,
      message: APP_STATUS.ERROR.REGISTRATION_EXEEDED_NUMBER_OF_TENTANTS_BODY
    });
    return false;
  }

  subscription.dealStage = DEAL_STAGE.Registered;
  const responses = await Promise.all([
    patchUpdateSubscriptionTenant(subscription, tenantId),
    patchAddAccount(subscriptionId, tenantId, tenantEmail, USER_TYPE.Admin, true)
  ]);

  const isSuccess = responses.every((res) => res);

  if (isSuccess) {
    makeToast({
      type: TOAST_TYPE.SUCCESS,
      title: APP_STATUS.SUCCESS.REGISTRATION_COMPLETE_TITLE,
      message: APP_STATUS.SUCCESS.REGISTRATION_COMPLETE_BODY
    });
    return true;
  }

  makeToast({
    type: TOAST_TYPE.ERROR,
    title: APP_STATUS.ERROR.REGISTRATION_ISSUE_TITLE,
    message: APP_STATUS.ERROR.REGISTRATION_ISSUE_BODY
  });

  return false;
};

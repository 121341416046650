import { FunctionComponent, useState } from 'react';
import { IconType } from 'react-icons';
import cn from 'classnames';

export type SideBarProps = {
  navItems: {
    name: string;
    Icon: IconType;
  }[];
  className?: string;
  onClick: (naveName: string) => void;
};

export const SideBar: FunctionComponent<SideBarProps> = ({
  navItems,
  onClick,
  className
}) => {
  const [selectedItem, setSelectedItem] = useState(navItems[0].name);

  const handleChangeNavItem = (name: string) => {
    setSelectedItem(name);
    onClick(name);
  };

  return (
    <div className={cn('min-h-screen w-72 px-6 py-4 shadow-2xl', className)}>
      <h1 className="mb-6 mt-3 text-xl font-semibold">PDF Markup - Admin</h1>
      <hr className="my-4" />
      {navItems.map(({ name, Icon }) => (
        <button
          type="button"
          key={name}
          onClick={() => handleChangeNavItem(name)}
          className={cn('mb-4 flex w-full items-center rounded-lg px-4 py-3', {
            'bg-red-500 text-white shadow-xl': selectedItem === name
          })}
        >
          <Icon
            className={cn('mr-4 h-6 w-6', {
              'text-white': selectedItem === name
            })}
          />
          {name}
        </button>
      ))}
    </div>
  );
};

import { HTTP_METHOD } from '@constants';
import { hubspotExecutor } from '@services';
import { Subscription } from '@types';

export const patchUpdateSubscriptionTenant = async (
  subscription: Subscription,
  tenantId: string
) => {
  const endpoint = `/records/${subscription.dealId}`;

  let tenantIds = '';

  if (!subscription.tenantIds?.length) {
    tenantIds = tenantId;
  } else if (!subscription.tenantIds.includes(tenantId)) {
    tenantIds = `${subscription.tenantIds};${tenantId}`;
  } else {
    tenantIds = subscription.tenantIds;
  }

  const graphQuery = {
    properties: {
      dealstage: subscription.dealStage,
      tenant_id: tenantIds
    }
  };

  return hubspotExecutor(HTTP_METHOD.PATCH, endpoint, graphQuery);
};

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { MSAL_CONFIG } from '@configs';

export const initMSA = () => {
  const msalInstance = new PublicClientApplication(MSAL_CONFIG);

  const accounts = msalInstance.getAllAccounts();

  if (accounts.length) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback(({ eventType, payload }: EventMessage) => {
    if (eventType === EventType.LOGIN_SUCCESS && payload) {
      const account = (payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};

export const msalInstance = initMSA();

import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import {
  APP_ROUTE,
  APP_STATUS,
  SEARCH_PARAMS_KEY,
  TOAST_TYPE
} from '@constants';
import {
  checkIsAdmin,
  getFile,
  saveDocument,
  sendAnnotationHub,
  sendAnnotation,
  loadAnnotations,
  msalInstance
} from '@services';
import { makeToast } from '@helpers';
import { SubscriptionContext, UserTenantContext } from '@contexts';
import { EditorLoader } from '@components';
import toast from 'react-hot-toast';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import { WEB_API_URL, HUB_API, HUB_ENABLE } from '../constants/common';

const viewerId = 'pdf-viewer';

export const Editor = () => {
  const subscription = useContext(SubscriptionContext);
  const { tenantUserDisplayName, tenantUserEmail, tenantId } =
    useContext(UserTenantContext);
  const [loading, setLoading] = useState(true);
  const viewerInstanceRef = useRef<WebViewerInstance | null>();
  const fileInfoRef = useRef<any | null>();
  const navigate = useNavigate();
  const webApiUrl = WEB_API_URL;
  const hubApiUrl = WEB_API_URL + HUB_API;

  let connection: HubConnection,
    documentId: any = null,
    authorId: any = null,
    hubEnabled: boolean = HUB_ENABLE;

  const { search } = useLocation();

  const paramRealTime = new URLSearchParams(search.toLowerCase()).get(
    SEARCH_PARAMS_KEY.REAL_TIME.toLowerCase()
  );

  if (paramRealTime != null) {
    hubEnabled = true;
  }

  if (hubEnabled) {
    connection = new HubConnectionBuilder()
      .withUrl(hubApiUrl)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log('connecting...');
    connection
      .start()
      .then(() =>
        console.log(
          'Now connected, connection ID = ' + connection?.connectionId
        )
      )
      .catch((err) =>
        console.log('Error while establishing connection :( ' + err)
      );

    connection.onclose(() => {
      console.log('Connection disconnected');
    });
  }

  useEffect(() => {
    initWebviewer();
  }, []);

  const initWebviewer = async () => {
    debugger;
    const fileInfo = await getFile();
    const sourceAbsoluteFileUrl = fileInfo.webUrl;

    if (!fileInfo) return;

    const fileExt = fileInfo.name.split('.').pop();

    const viewerInstance = await WebViewer(
      {
        path: '/webviewer',
        useDownloader: false,
        extension: fileExt,
        fullAPI: true,
        licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        enableRedaction: true,
        annotationUser: tenantUserDisplayName,
        initialDoc: fileInfo['@microsoft.graph.downloadUrl']
      },
      document.getElementById(viewerId)!
    );

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FILE_IS_LOADING,
      loading
    });

    setLoading(false);

    viewerInstanceRef.current = viewerInstance;
    fileInfoRef.current = fileInfo;

    const {
      id
    } = fileInfoRef.current;
    documentId = id;

    const {
      Core: { annotationManager, documentViewer }
    } = viewerInstanceRef.current;

    appendSaveButton();
    appendAdminButton();

    annotationManager.addEventListener(
      'annotationChanged',
      async (annotations, action, options) => {
        if (options.imported) return;

        const formFields = annotations.map(
          (annotation: { isFormFieldPlaceholder: () => any }) =>
            annotation.isFormFieldPlaceholder()
        );
        if (formFields.some((field: boolean) => field === true)) return;

        const xfdfStrings = await annotationManager.exportAnnotationCommand();
        console.log('XFDF from exportAnnotationCommand()', xfdfStrings);

        if (hubEnabled) {
          console.log(
            action,
            documentId,
            annotations[0].Id,
            xfdfStrings,
            sourceAbsoluteFileUrl,
            tenantUserEmail,
            tenantUserDisplayName,
            authorId
          );

          sendAnnotationHub(
            connection,
            action,
            documentId,
            annotations[0].Id,
            xfdfStrings,
            sourceAbsoluteFileUrl,
            tenantUserEmail,
            tenantUserDisplayName,
            authorId
          );
        } else {
          console.log(
            action,
            documentId,
            annotations[0].Id,
            xfdfStrings,
            sourceAbsoluteFileUrl,
            tenantUserEmail,
            tenantUserDisplayName,
            authorId
          );

          sendAnnotation(
            webApiUrl,
            action,
            documentId,
            annotations[0].Id,
            xfdfStrings,
            sourceAbsoluteFileUrl,
            tenantUserEmail,
            tenantUserDisplayName,
            authorId
          );
        }
      }
    );

    viewerInstance.UI.addEventListener('documentLoaded', () => {
      toast.dismiss(loadingToastId);
      makeToast({
        type: TOAST_TYPE.SUCCESS,
        message: APP_STATUS.SUCCESS.FILE_IS_IMPORTED
      });
    });

    documentViewer.on('documentLoaded', async () => {
      const account = msalInstance.getActiveAccount();
      authorId = account?.localAccountId;
      const xfdfStringRows = await loadAnnotations(webApiUrl, documentId);
      xfdfStringRows.forEach(async (row: { xfdfString: string }) => {
        const annotat = await annotationManager.importAnnotCommand(
          row.xfdfString
        );
        annotationManager.drawAnnotationsFromList(annotat);
      });

      if (hubEnabled) {
        viewerInstance.UI.disableElements([
          'thumbRotateClockwise',
          'thumbDelete',
          'pageManipulationOverlayButton'
        ]);
        var userConnection: any = {};
        userConnection.User = tenantUserEmail;
        userConnection.Document = documentId;

        connection.invoke('JoinDocument', userConnection);
      }
    });

    if (hubEnabled) {
      connection.on(
        'ReceiveAnnotationHub',
        async (user, receiveDocumentId, receiveXfdfString) => {
          if (receiveDocumentId === documentId) {
            const annotations = await annotationManager.importAnnotationCommand(
              receiveXfdfString
            );
            await annotationManager.drawAnnotationsFromList(annotations);
          }
        }
      );
    }
  };

  const appendSaveButton = () => {
    if (!viewerInstanceRef.current) return;

    const saveButton = {
      type: 'actionButton',
      img: '../assets/save-button.svg',
      onClick: handleSaveDocument
    };

    viewerInstanceRef.current?.UI.setHeaderItems((header) =>
      header.push(saveButton)
    );
  };

  const appendAdminButton = async () => {
    const adminAccount = await checkIsAdmin(
      subscription.subscriptionId,
      tenantId,
      tenantUserEmail
    );

    if (!adminAccount) return;

    const adminButton = {
      type: 'actionButton',
      img: '../assets/admin-button.svg',
      onClick: () => navigate(APP_ROUTE.ADMIN)
    };

    viewerInstanceRef.current?.UI.setHeaderItems((header) =>
      header.push(adminButton)
    );
  };

  const handleSaveDocument = async () => {
    if (!viewerInstanceRef.current || !fileInfoRef.current) return;

    const {
      Core: { documentViewer, annotationManager }
    } = viewerInstanceRef.current;

    const {
      id,
      parentReference: { driveId }
    } = fileInfoRef.current;
    
    const document = documentViewer.getDocument();
    await saveDocument(
      annotationManager,
      document,
      subscription,
      tenantId,
      tenantUserEmail,
      driveId,
      id
    );
  };

  return (
    <div id={viewerId} className="h-screen">
      {loading && <EditorLoader />}
    </div>
  );
};

import { UPLOAD_FILE_SLICE, APP_STATUS, TOAST_TYPE } from '@constants';
import { makeToast } from '@helpers';
import { uploadChunkExecutor } from '@services';
import toast from 'react-hot-toast';

export const chunkingAndUploadFile = (
  file: Blob,
  uploadUrl: string,
  loadingToastId: string = '',
  position: number = 0
) => {
  const fileReader = new FileReader();

  const nextSlice = position + UPLOAD_FILE_SLICE;

  const fragment = file.slice(position, nextSlice);

  fileReader.readAsArrayBuffer(fragment);

  fileReader.onloadend = (event) => {
    if (event.target?.readyState !== fileReader.DONE) {
      return;
    }

    const chunk = fileReader.result as ArrayBuffer;

    uploadChunkExecutor(chunk, uploadUrl, position, file.size)
      .then((response: { status: number }) => {
        if (response.status === 202) {
          const nextPosition = position + UPLOAD_FILE_SLICE;
          chunkingAndUploadFile(file, uploadUrl, loadingToastId, nextPosition);

          return;
        } else if (response.status === 200) {
          toast.dismiss(loadingToastId);
          
          makeToast({
            message: APP_STATUS.SUCCESS.PDF_SAVED_MESSAGE,
            type: TOAST_TYPE.SUCCESS
          });

          return;
        } else {
          makeToast({
            message: APP_STATUS.ERROR.PDF_SAVED_MESSAGE,
            type: TOAST_TYPE.ERROR
          });

          return;
        }
      })
      .catch((err) => {
        throw Error(err);
      });
  };
};

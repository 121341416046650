import { HTTP_METHOD } from '@constants';

export const uploadChunkExecutor = async (
  chunk: ArrayBuffer,
  uploadURL: string,
  position: number,
  fileSize: number
) => {
  const max = position + chunk.byteLength - 1;

  const crHeader = 'bytes ' + position + '-' + max + '/' + fileSize;

  const headers: HeadersInit = {
    'Content-Type': 'application/octet-stream',
    'Content-Range': crHeader
  };

  const options: RequestInit = {
    method: HTTP_METHOD.PUT,
    headers: headers,
    body: chunk
  };

  return fetch(uploadURL, options);
};

export const LOCAL_HOST = 'http://localhost:8080';

//Local Service URL
// export const WEB_API_URL = 'https://localhost:44382';

//Custome Service URL
export const WEB_API_URL = 'https://pdfeditorapi.azurewebsites.net';

//API EndPoints
export const SEND_ANNOTATION = '/api/Annotation/SendAnnotation/';
export const GET_ANNOTATION = '/api/Annotation/GetAnnotation/';

//Hub API EndPoints
export const HUB_API = '/PDFEditor';
export const SEND_ANNOTATION_HUB = 'SendAnnotationHub';

//SignalR false globally
export const HUB_ENABLE = false;

export const GRAPH_SCOPES = ['Files.ReadWrite.All', 'User.Read'];

export const CONFIG_TRIAL_MAX_NUMBER_OF_USERS = 9999;
export const CONFIG_TRIAL_NUMBER_OF_DAYS = 30;
export const MAX_TENANTS_NUM = 3;

export const SOHO_PRODUCT_TYPES = {
  SHAREPOINT_TEAMS: {
    title: 'PDF Editor SharePoint and Teams',
    productCode: 'reculyplansharepointandteams'
  }
};

export enum DEAL_STAGE {
  'Active' = '9047089',
  'Registered' = '9047091',
  'Expired' = '9047093',
  'Cancelled' = '9047094',
  'Paused' = '9047095'
}

export enum USER_TYPE {
  'Admin' = 'Admin',
  'User' = 'User'
}

export enum HTTP_METHOD {
  'GET' = 'GET',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'PATCH' = 'PATCH',
  'DELETE' = 'DELETE',
  'MERGE' = 'MERGE'
}

export enum TOAST_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  LOADING = 'loading'
}

export const UPLOAD_FILE_SLICE = 10 * 1028 * 1028;

import { MS_GRAPH_ENDPOINT, HTTP_METHOD } from '@constants';
import { msGraphExecutor } from '@services';

export const putCreateUploadSession = (driveId: string, itemId: string) => {
  const url = `${MS_GRAPH_ENDPOINT.DRIVES}/${driveId}/items/${itemId}/createUploadSession`;

  const payload = {
    item: {
      '@microsoft.graph.conflictBehavior': 'replace'
    },
    deferCommit: true
  };

  return msGraphExecutor(HTTP_METHOD.PUT, url, payload);
};

import {
  TOAST_TYPE,
  APP_STATUS,
  SEND_ANNOTATION,
  SEND_ANNOTATION_HUB,
  GET_ANNOTATION
} from '@constants';
import { makeToast } from '@helpers';
import { Core } from '@pdftron/webviewer';
import {
  putCreateUploadSession,
  chunkingAndUploadFile,
  validateActions
} from '@services';
import { Subscription } from '@types';
import axios from 'axios';

export const loadAnnotations = async (webApiUrl: any, documentId: any) => {
  try {
    const response: any = await axios
      .get(webApiUrl + GET_ANNOTATION + `?documentId=${documentId}`)
      .catch((error: any) => {
        console.log(error);
        makeToast({
          message: APP_STATUS.ERROR.CONNECTION_ERROR,
          type: TOAST_TYPE.ERROR
        });
      });
    if (response.status !== 200) {
      makeToast({
        message: APP_STATUS.ERROR.CONNECTION_ERROR,
        type: TOAST_TYPE.ERROR
      });
    }
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const saveAnnotationChange = async (
  annotationManager: Core.AnnotationManager,
  document: Core.Document,
  subscription: Subscription,
  tenantId: string,
  tenantUserEmail: string,
  driveId: string,
  fileId: string
) => {
  const isActionsValid = await validateActions(
    subscription,
    tenantId,
    tenantUserEmail
  );

  if (isActionsValid) {
    const [uploadSession, xfdfString] = await Promise.all([
      putCreateUploadSession(driveId, fileId),
      annotationManager.exportAnnotations()
    ]);

    const uploadUrl = uploadSession.uploadUrl as string;

    if (!uploadUrl) {
      makeToast({
        message: APP_STATUS.ERROR.PDF_SAVED_MESSAGE,
        type: TOAST_TYPE.ERROR
      });
      return;
    }

    const fileData = await document.getFileData({ xfdfString });

    const fileArray = new Uint8Array(fileData);
    const blobFile = new Blob([fileArray], { type: 'application/pdf' });

    await chunkingAndUploadFile(blobFile, uploadUrl);
  }
};

export async function sendAnnotationHub(
  annotationHubProxy: any,
  action: any,
  documentId: any,
  annotationId: any,
  xfdfString: string,
  fileAbsoluteUrl: any,
  tenantUserEmail: string,
  tenantUserDisplayName: string,
  authorId: string
) {
  try {
    console.info('sending annotation message');
    var annotation: any = {};
    annotation.Action = action;
    annotation.DocumentId = documentId;
    annotation.AnnotationId = annotationId;
    annotation.XfdfString = xfdfString;
    annotation.FileAbsoluteUrl = fileAbsoluteUrl;
    annotation.UserEmail = tenantUserEmail;
    annotation.UserName = tenantUserDisplayName;
    annotation.AuthorId = authorId;

    const response: any = await annotationHubProxy.invoke(
      SEND_ANNOTATION_HUB,
      annotation
    );
    console.log(response.value);
    if (response.statusCode !== 200) {
      makeToast({
        message: APP_STATUS.ERROR.CONNECTION_ERROR,
        type: TOAST_TYPE.ERROR
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export const sendAnnotation = async (
  webApiUrl: any,
  action: any,
  documentId: any,
  annotationId: any,
  xfdfString: string,
  fileAbsoluteUrl: any,
  tenantUserEmail: string,
  tenantUserDisplayName: string,
  authorId: string
) => {
  try {
    console.info('sending annotation message');
    var annotation: any = {};
    annotation.Action = action;
    annotation.DocumentId = documentId;
    annotation.AnnotationId = annotationId;
    annotation.XfdfString = xfdfString;
    annotation.FileAbsoluteUrl = fileAbsoluteUrl;
    annotation.UserEmail = tenantUserEmail;
    annotation.UserName = tenantUserDisplayName;
    annotation.AuthorId = authorId;

    const response: any = await axios
      .post(webApiUrl + SEND_ANNOTATION, annotation)
      .catch((error: any) => {
        console.log(error);
        makeToast({
          message: APP_STATUS.ERROR.CONNECTION_ERROR,
          type: TOAST_TYPE.ERROR
        });
      });
    console.log(response.data);
    if (response.status !== 200) {
      makeToast({
        message: APP_STATUS.ERROR.CONNECTION_ERROR,
        type: TOAST_TYPE.ERROR
      });
    }
  } catch (e) {
    console.log(e);
  }
};

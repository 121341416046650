import { HTTP_METHOD } from '@constants';
import { storageExecutor } from '@services';

export const patchAddAccount = async (
  subscriptionId: string,
  tenantId: string,
  userEmail: string,
  accountType: string,
  primaryAdmin: boolean = false
) => {
  const url = `/add/${subscriptionId};${tenantId}/${userEmail};${accountType}`;

  const payload = {
    Email: userEmail,
    AccountType: accountType,
    SubscriptionId: subscriptionId,
    TenantId: tenantId,
    PrimaryAdmin: primaryAdmin
  };

  return storageExecutor(HTTP_METHOD.PATCH, url, payload);
};

import { APP_ROUTE, DEAL_STAGE } from '@constants';
import { UserTenantContext } from '@contexts';
import { getSubscriptionByTenantId, checkAndPersistUrlParams } from '@services';
//import { getSubscriptionBySubscriptionId, checkAndPersistUrlParams } from '@services';
import { Subscription } from '@types';
import { SubscriptionLoader } from '@components';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const subscriptionDefaultValue: Subscription = {
  dealId: '',
  numberOfUsers: 0,
  enterpriseAgreement: false,
  subscriptionId: '',
  tenantIds: '',
  adminAccount: '',
  productCode: '',
  created: new Date(),
  dealStage: DEAL_STAGE.Active,
  registeredTenantNum: 0,
  isValid: false
};

export type SubscriptionContextState = Subscription;

export const SubscriptionContext = createContext<SubscriptionContextState>(
  subscriptionDefaultValue
);

export type SubscriptionProviderProps = {
  children: ReactNode;
};

export const SubscriptionProvider: FunctionComponent<SubscriptionProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { tenantId } = useContext(UserTenantContext);
  
  const [subscription, setSubscription] = useState<SubscriptionContextState>(
    subscriptionDefaultValue
  );
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    fetchAndSetSubcription();
  }, []);

  const fetchAndSetSubcription = async () => {
    const lowerCaseParams = new URLSearchParams([...searchParams].map(([key, value]) => [key.toLowerCase(), value]));
    const subscriptionResponse = await getSubscriptionByTenantId(tenantId);
    const isValidParam = checkAndPersistUrlParams(lowerCaseParams);
    if (!subscriptionResponse?.isValid) {
      if (!isValidParam) {
        navigate(APP_ROUTE.NOT_FOUND);
      }
      navigate(APP_ROUTE.REGISTRATION);
      setLoading(false);
      
    } else {
      setSubscription(subscriptionResponse);
    }

    setLoading(false);
  };

  if (loading) return <SubscriptionLoader />;

  return (
    <SubscriptionContext.Provider value={subscription}>
      {children}
    </SubscriptionContext.Provider>
  );
};

import { LoginLoader } from '@components';
import { getTenantInfo, getUserInfo } from '@services';
import { silentAcquireGrapthToken } from '@services';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';

const contextDefaultValue = {
  tenantId: '',
  tenantUserEmail: '',
  tenantUserDisplayName: ''
};

export type UserTenantContextState = {
  tenantId: string;
  tenantUserEmail: string;
  tenantUserDisplayName: string;
};

export const UserTenantContext =
  createContext<UserTenantContextState>(contextDefaultValue);

export type UserTenantProviderProps = {
  children: ReactNode;
};

export const UserTenantProvider: FunctionComponent<UserTenantProviderProps> = ({
  children
}) => {
  const [userTenantInfo, setUserTenantInfo] =
    useState<UserTenantContextState>(contextDefaultValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAndSetInfo();
  }, []);

  const fetchAndSetInfo = async () => {
    await silentAcquireGrapthToken();

    const [tenantInfo, userInfo] = await Promise.all([
      getTenantInfo(),
      getUserInfo()
    ]);

    setUserTenantInfo({
      tenantId: tenantInfo.value[0].id,
      tenantUserEmail: userInfo.mail,
      tenantUserDisplayName: userInfo.displayName
    });

    setLoading(false);
  };

  if (loading) return <LoginLoader />;

  return (
    <UserTenantContext.Provider value={userTenantInfo}>
      {children}
    </UserTenantContext.Provider>
  );
};

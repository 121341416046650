import { HTTP_METHOD } from '@constants';
import { storageExecutor } from '@services';

export const getAccount = async (
  subscriptionId: string,
  tenantId: string,
  emailAddress: string,
  accountType: string
) => {
  const url = `/get?$filter=PartitionKey eq '${subscriptionId};${tenantId}' and RowKey eq '${emailAddress};${accountType}'`;

  return storageExecutor(HTTP_METHOD.GET, url);
};

import { HTTP_METHOD, TOAST_TYPE, APP_STATUS, RECURLY_BASE_URL } from '@constants';
import { makeToast } from '@helpers';

export const recurlyExecutor = async (
  method: HTTP_METHOD,
  endpoint: string,
  payload?: unknown
) => {
  try {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };

    const options: RequestInit = {
      method: method,
      headers: headers,
      body: JSON.stringify(payload)
    };

    return await fetch(`${RECURLY_BASE_URL}${endpoint}`, options);
  } catch (error) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.RECURLY_SERVER_ERROR_TITLE,
      message: APP_STATUS.ERROR.RECURLY_SERVER_ERROR_BODY
    });
    throw error;
  }
};

export const SEARCH_PARAMS_KEY = {
  SITE_ID: 'siteid',
  LIBRARY_NAME: 'libraryname',
  LIBRARY_ID: 'lid',
  FILE_PATH: 'filepath',
  CURRENT_USER_EMAIL: 'u',
  CLIENT_VERSION: 'v',
  DRIVE_FILE_ID: 'drivefileid',
  TENANT_ID: 'tenantid',
  TENANT_NAME: 'tenantname',
  EMAIL_ADDRESS: 'email',
  REAL_TIME: 'rt',
  RECURLY_NUMBER_OF_USERS: 'noofusers',
  RECURLY_PLAN_CODE: 'plancode',
  RECURLY_API_CODE: 'code'
};

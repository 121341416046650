import { TOAST_TYPE } from '@constants';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import {
  BsFillExclamationCircleFill,
  BsFillInfoCircleFill,
  BsFillXCircleFill,
  BsPatchCheckFill
} from 'react-icons/bs';

import './styles/app-toaster.scss';

const config = {
  success: {
    Icon: BsPatchCheckFill,
    defaultTitle: 'Success',
    containerClass: 'border-green-300 bg-green-50',
    iconContainerClass: 'rounded-md bg-green-500 shadow-green-100'
  },
  info: {
    defaultTitle: 'Information',
    Icon: BsFillInfoCircleFill,
    containerClass: 'border-blue-300 bg-blue-50',
    iconContainerClass: 'rounded-md bg-blue-500 shadow-blue-100'
  },
  warning: {
    defaultTitle: 'Warning',
    Icon: BsFillExclamationCircleFill,
    containerClass: 'border-yellow-300 bg-yellow-50',
    iconContainerClass: 'rounded-md bg-yellow-500 shadow-yellow-100'
  },
  error: {
    defaultTitle: 'Error',
    Icon: BsFillXCircleFill,
    containerClass: 'border-red-300 bg-red-50',
    iconContainerClass: 'rounded-md bg-red-500 shadow-red-100'
  },
  loading: {
    defaultTitle: 'Loading',
    Icon: AiOutlineLoading3Quarters,
    containerClass: 'border-gray-300 bg-gray-50',
    iconContainerClass: 'animate-spin bg-gray-400 rounded-full'
  }
};

export type ToasterComponentProps = {
  type: TOAST_TYPE;
  title?: string;
  message: string;
};

export const AppToaster: FunctionComponent<ToasterComponentProps> = ({
  type,
  title,
  message
}) => {
  const { Icon, containerClass, iconContainerClass, defaultTitle } =
    config[type];

  return (
    <div
      className={cn('flex items-center space-x-3 border p-3', containerClass)}
      style={{ borderRadius: 12 }}
    >
      <div
        className={cn(
          'mt-1 flex h-10 w-10 flex-shrink-0 resize-none items-center justify-center',
          iconContainerClass
        )}
      >
        <Icon className="text-2xl text-white" />
      </div>
      <div>
        <div className="font-semibold text-gray-900">
          {title || defaultTitle}
        </div>
        <div className="text-sm font-light text-gray-600">{message}</div>
      </div>
    </div>
  );
};

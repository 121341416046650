import { FunctionComponent, ReactNode } from 'react';
import {
  AppMsalProvider,
  SubscriptionProvider,
  UserTenantProvider
} from '@contexts';

export type ProviderWrapperProps = {
  children: ReactNode;
};

export const ProviderWrapper: FunctionComponent<ProviderWrapperProps> = ({
  children
}) => {
  return (
    <AppMsalProvider>
      <UserTenantProvider>
        <SubscriptionProvider>{children}</SubscriptionProvider>
      </UserTenantProvider>
    </AppMsalProvider>
  );
};

import { CONFIG_TRIAL_MAX_NUMBER_OF_USERS } from '@constants';
import { Subscription } from '@types';
import { isTrialUser } from '@helpers';
 
export const GetMaximumAllowedUsers = (subscription: Subscription): number => {
  const trialUser = isTrialUser(subscription.created);
  if (trialUser) {
    return CONFIG_TRIAL_MAX_NUMBER_OF_USERS;
  }
  return Number(subscription.numberOfUsers);
};

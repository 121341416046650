import { SEARCH_PARAMS_KEY, SESSION_STORAGE_KEY } from '@constants';
import { setSessionStorageItem, getSessionStorageItem } from '@services';

export const checkAndPersistUrlParams = (
  searchParams: URLSearchParams
): boolean => {
  debugger;

  const paramDriveFileId = searchParams.get(SEARCH_PARAMS_KEY.DRIVE_FILE_ID);
  const paramSiteId = searchParams.get(SEARCH_PARAMS_KEY.SITE_ID);
  const paramFilePath = searchParams.get(SEARCH_PARAMS_KEY.FILE_PATH);
  const paramLibraryName = searchParams.get(SEARCH_PARAMS_KEY.LIBRARY_NAME);
  const paramLibraryId = searchParams.get(SEARCH_PARAMS_KEY.LIBRARY_ID);

  if (paramDriveFileId) {
    setSessionStorageItem(SESSION_STORAGE_KEY.DRIVE_FILE_ID, paramDriveFileId);
    return true;
  } else if (
    paramSiteId &&
    paramFilePath &&
    (paramLibraryName || paramLibraryId)
  ) {
    setSessionStorageItem(SESSION_STORAGE_KEY.SITE_ID, paramSiteId);
    setSessionStorageItem(SESSION_STORAGE_KEY.FILE_PATH, paramFilePath);
    setSessionStorageItem(SESSION_STORAGE_KEY.LIBRARY_NAME, paramLibraryName);
    setSessionStorageItem(SESSION_STORAGE_KEY.LIBRARY_ID, paramLibraryId);
    return true;
  } else {
    //no parameters in the url try last known file
    const currentSiteId = getSessionStorageItem(SESSION_STORAGE_KEY.SITE_ID);
    const currentdriveFileId = getSessionStorageItem(SESSION_STORAGE_KEY.DRIVE_FILE_ID);
    if (currentSiteId || currentdriveFileId) return true;
  }
  
  return false;
};

 
import { APP_STATUS, DEAL_STAGE, TOAST_TYPE } from '@constants';
import { Subscription } from '@types';
 
import { makeToast, GetMaximumAllowedUsers } from '@helpers';
import { validateUser,   getAccountCount, getAccounts } from '@services';
 
export const validateActions = async (
  subscription: Subscription,
  tenantId: string,
  tenantUserEmail: string
) => {
  const { dealStage, enterpriseAgreement } = subscription;
    
  if (enterpriseAgreement) {
    return true;
  }

  if (dealStage === DEAL_STAGE.Expired) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.SUBSCRIPTION_EXPIRED_TITLE,
      message: APP_STATUS.ERROR.SUBSCRIPTION_EXPIRED_BODY
    });

    return false;
  }

  if (dealStage === DEAL_STAGE.Cancelled) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.SUBSCRIPTION_CANCELLED_TITLE,
      message: APP_STATUS.ERROR.SUBSCRIPTION_CANCELLED_BODY
    });

    return false;
  }

  const { subscriptionId } = subscription;

  const usersCount = await getAccountCount(subscriptionId);
  const accounts = usersCount.value;

  const response = await getAccounts(subscriptionId, tenantId, 'User');
  const userList = await response.value;

  const isAccountExist = userList.find(
    (account :any) => account.Email === tenantUserEmail
  );

  const maxAllowedUser = GetMaximumAllowedUsers(subscription);

  if (!isAccountExist && maxAllowedUser <= accounts.length) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      message: APP_STATUS.ERROR.USER_LIMIT_REACHED
    });
    return false;
  }

  const validUser = await validateUser(subscription, tenantId, tenantUserEmail);
 
  if (!validUser) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.INSUFFICIENT_USER_LICENSES_TITLE,
      message: APP_STATUS.ERROR.INSUFFICIENT_USER_LICENSES_BODY
    });

    return false;
  }
  return true;
};

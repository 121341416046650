import { getLocalStorageItem } from '@services';
import {
  MS_GRAPH_BASE_URL,
  HTTP_METHOD,
  LOCAL_STORAGE_KEY,
  TOAST_TYPE,
  APP_STATUS
} from '@constants';
import { makeToast } from '@helpers';

export const msGraphExecutor = async (
  method: HTTP_METHOD,
  endpoint: string,
  payload?: unknown
) => {
  const graphToken = getLocalStorageItem(LOCAL_STORAGE_KEY.GRAPH_TOKEN);

  try {
    const headers: HeadersInit = {
      Authorization: `Bearer ${graphToken}`,
      'Content-Type': 'application/json'
    };

    const options: RequestInit = {
      method: method,
      headers: headers,
      body: JSON.stringify(payload)
    };

    const response = await fetch(`${MS_GRAPH_BASE_URL}${endpoint}`, options);

    if (response.status === 204) return;
    return await response.json();
  } catch (error) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.MICROSOFT_SERVER_ERROR_TITLE,
      message: APP_STATUS.ERROR.MICROSOFT_SERVER_ERROR_BODY
    });
    throw error;
  }
};

import { HTTP_METHOD } from '@constants';
import { storageExecutor } from '@services';

export const getAccountCount = async (
  subscriptiondId: string
  //tenantId: string
  //accountType: string
) => {
  const url = `/get?$filter=SubscriptionId eq '${subscriptiondId}'`;
  return storageExecutor(HTTP_METHOD.GET, url);
};
